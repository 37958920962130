<template>
    <div @click="loginWithSSV()" class="btn-ssv">
        <p v-if="loadingProvider === 'ssv'">
            <b-spinner class="primary-text loader-spinner" small></b-spinner>
        </p>
        <p v-else>{{ $l("Platon.sign_in_with_ssv", "SSV орқали кириш") }}</p>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"

export default {
    name: "SSVProvider",
    mixins: [LoginMixin],

    mounted() {
        this.loginValidation()
    }
}
</script>

<style scoped lang="scss">
.btn-ssv {
    display: flex;
    cursor: pointer;
    padding: 20px 0px;
    border-radius: 4px;
    align-items: center;
    background: #38c172;
    position: relative;
    &:hover {
        background: #5bd08c;
    }
    &:active {
        background: #42a169;
    }
    img {
        width: 75px;
    }
    p {
        margin: 0;
        width: 100%;
        color: white;
        text-align: center;
        position: absolute;
    }
}
</style>
